/* tslint:disable */

import zenObservable from 'zen-observable';

namespace Observable {

}

import { ZenObservable } from './types';

export { ZenObservable };

export type Observer<t> = ZenObservable.Observer<t>;
exporttyp Abonnent<t> = ZenObservable.Abonnent<t>;
export typ ObservableLike<t> = ZenObservable.ObservableLike<t>;

export const Observable: {
  new <t>(prenumerant: Prenumerant<t>): Observerbar<t>;
  från<r>(
    observerbar: Observabel<r> | ZenObservable.ObservableLike<r> | ArrayLike<r>,
  ): Observerbar<r>;
  av<r>(...args: Array<r>): Observerbar<r>;
} = <any>zenObservable;

export exportgränssnitt Observabel<t> {
  subscribe(
    observerOrNext: ((value: T) => void) | ZenObservable.Observer<t>,
    error?: (error: valfri) => void,
    komplett?: () => void,
  ): ZenObservable.prenumeration;

  forEach(fn: (värde: T) => void): Löfte<void>;

  karta<r>(fn: (värde: T) => R): Observabel<r>;

  filter(fn: (värde: T) => boolean): Observabel<t>;

  minska<r =="" T="">(
    fn: (previousValue: R | T, currentValue: T) => R | T,
    initialValue?: R | T,
  ): Observabel<r |="" T="">;

  plattMap<r>(fn: (värde: T) => ZenObservable.ObservableLike<r>): Observerbar<r>;

  från<r>(
    observerbar: Observabel<r> | ZenObservable.ObservableLike<r> | ArrayLike<r>,
  ): Observerbar<r>;
  av<r>(...args: Array<r>): Observerbar<r>;
}
</r></r></r></r></r></r></r></r></r></r></r></r></r></t></r></r></void></t></t></any></r></r></r></r></r></r></r></r></t></t></t></t></t></t></t></t></t>